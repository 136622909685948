import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import blob from '../assets/images/cool-background-white.svg'

const Gdpr = () => {
  const { t } = useTranslation()
  return (
    <Wrapper>
      <div className='content-container'>
        <h1>{t('gdpr')}</h1>
        <div>
          <h2>{t('g-title-1')}</h2>
          <span>{t('g-desc-1')}</span>
          <h2>{t('g-title-2')}</h2>
          <div>
            <span className='list-elem'>{t('g-desc-2-1')} </span>
          </div>
          <div>
            <span className='list-elem'>{t('g-desc-2-2')} </span>
          </div>
          <div>
            <span className='list-elem'>{t('g-desc-2-3')} </span>
          </div>
          <div>
            <span className='list-elem'>{t('g-desc-2-4')} </span>
          </div>
          <h2>{t('g-title-2-1')}</h2>
          <span>{t('g-desc-2-1-1')}</span>
          <h2>{t('g-title-3')}</h2>
          <span>{t('g-desc-3')}</span>
          <div>
            <span className='list-elem'>{t('g-desc-3-1')} </span>
          </div>
          <div>
            <span className='list-elem'>{t('g-desc-3-2')} </span>
          </div>
          <div>
            <span className='list-elem'>{t('g-desc-3-3')} </span>
          </div>
          <div>
            <span className='list-elem'>{t('g-desc-3-4')} </span>
          </div>
          <span>{t('g-desc-3-5')}</span>
          <span>{t('g-desc-3-6')}</span>
          <span>{t('g-desc-3-7')}</span>
          <h2>{t('g-title-4-1')}</h2>
          <span>{t('g-desc-4-1')}</span>
          <span>{t('g-desc-4-2')}</span>
          <span>{t('g-desc-4-3')}</span>
          <h3>{t('g-title-4-1-1')}</h3>
          <span className='sub'>{t('g-desc-4-1-1')}</span>
          <h3>{t('g-title-4-1-2')}</h3>
          <span className='sub'>{t('g-desc-4-1-2')}</span>
          <h3>{t('g-title-4-1-3')}</h3>
          <span className='sub'>{t('g-desc-4-1-3')}</span>
          <h3>{t('g-title-4-1-4')}</h3>
          <span className='sub'>{t('g-desc-4-1-4')}</span>
          <h2>{t('g-title-5')}</h2>
          <span>{t('g-desc-5-1')}</span>
          <span>{t('g-desc-5-2')}</span>
          <h2>{t('g-title-6-1')}</h2>
          <span>{t('g-desc-6-1')}</span>
          <h3>{t('g-title-6-1-1')}</h3>
          <span className='sub'>{t('g-desc-6-1-1')}</span>
          <span className='sub'>{t('g-desc-6-1-2')}</span>
          <h3>{t('g-title-6-2-1')}</h3>
          <span className='sub'>{t('g-desc-6-2-1')}</span>
          <span className='sub'>{t('g-desc-6-2-2')}</span>
          <h3>{t('g-title-6-3-1')}</h3>
          <span className='sub'>{t('g-desc-6-3-1')}</span>
          <h3>{t('g-title-6-4-1')}</h3>
          <span className='sub'>{t('g-desc-6-4-1')}</span>
          <h3>{t('g-title-6-5-1')}</h3>
          <span className='sub'>{t('g-desc-6-5-1')}</span>
          <h2>{t('g-title-7')}</h2>
          <span>{t('g-desc-7')}</span>
          <h3>{t('g-title-7-1')}</h3>
          <span className='sub'>{t('g-desc-7-1')}</span>
          <h3>{t('g-title-7-2')}</h3>
          <span className='sub'>{t('g-desc-7-2')}</span>
          <div>
            <span className='list-elem sub'>{t('g-desc-7-2-1')} </span>
          </div>
          <div>
            <span className='list-elem sub'>{t('g-desc-7-2-2')} </span>
          </div>
          <div>
            <span className='list-elem sub'>{t('g-desc-7-2-3')} </span>
          </div>
          <div>
            <span className='list-elem sub'>{t('g-desc-7-2-4')} </span>
          </div>
          <div>
            <span className='list-elem sub'>{t('g-desc-7-2-5')} </span>
          </div>
          <div>
            <span className='list-elem sub'>{t('g-desc-7-2-6')} </span>
          </div>
          <div>
            <span className='list-elem sub'>{t('g-desc-7-2-7')} </span>
          </div>
          <div>
            <span className='list-elem sub'>{t('g-desc-7-2-8')} </span>
          </div>
          <span className='sub'>{t('g-desc-7-2-9')} </span>
          <h3>{t('g-title-7-3')}</h3>
          <span className='sub'>{t('g-desc-7-3')}</span>
          <h3>{t('g-title-7-4')}</h3>
          <span className='sub'>{t('g-desc-7-4-1')}</span>
          <span className='sub'>{t('g-desc-7-4-2')}</span>
          <h3>{t('g-title-7-5')}</h3>
          <span className='sub'>{t('g-desc-7-5-1')}</span>
          <span className='sub'>{t('g-desc-7-5-2')}</span>
          <h3>{t('g-title-7-6')}</h3>
          <span className='sub'>{t('g-desc-7-6')}</span>
          <h3>{t('g-title-7-7')}</h3>
          <span className='sub'>{t('g-desc-7-7')}</span>
          <h3>{t('g-title-7-8')}</h3>
          <span className='sub'>{t('g-desc-7-8')}</span>
          <h3>{t('g-title-7-9')}</h3>
          <span className='sub'>{t('g-desc-7-9')}</span>
          <h3>{t('g-title-7-10')}</h3>
          <span className='sub'>{t('g-desc-7-10')}</span>
          <h2>{t('g-title-8')}</h2>
          <span>{t('g-desc-8')}</span>
          <h3>{t('g-title-8-1')}</h3>
          <span className='sub'>{t('g-desc-8-1')}</span>
        </div>
      </div>
    </Wrapper>
  )
}

export default Gdpr

const Wrapper = styled.main`
  width: 100%;
  background: url(${blob}) no-repeat top/cover fixed;
  .content-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 200px;
    width: 1280px;
    height: 100%;
    max-width: 90%;
    margin: auto;
    padding-bottom: 5rem;

    @media screen and (max-width: 1024px) {
      width: 100%;
      padding-top: 30px;
    }
  }

  h1 {
    margin-bottom: 1rem;
  }

  h2,
  h3 {
    position: relative;
    margin-top: 2rem;
    margin-bottom: 1rem;
    text-transform: uppercase;
  }

  h2 {
    padding-left: 10px;
  }

  h3 {
    font-size: calc(var(--subtitle-font-size) - 0.1rem);
    padding-left: 10px;
    margin-left: 10px;
  }

  h2::before {
    position: absolute;
    left: 0;
    top: 0;
    content: '';
    width: 5px;
    height: 100%;
    background-color: var(--banner-color);
  }

  h3::before {
    position: absolute;
    left: 0;
    top: 0;
    content: '';
    width: 5px;
    height: 100%;
    background-color: var(--header-color);
  }

  span {
    display: block;
    padding: 0.5rem 0;
  }

  .sub {
    margin-left: 20px;
  }

  .list-elem {
    position: relative;
    display: flex;
    max-width: 100%;
    font-weight: 700;
    padding-left: 20px;
    padding-top: 0;
  }

  .list-elem::before {
    position: absolute;
    left: 0;
    top: -16px;
    content: '◼︎';
    padding-top: 1rem;
    color: var(--header-color);
  }

  .list-elem ~ span {
    display: block;
    padding-block: 0.5rem;
  }

  .bold {
    font-weight: 700;
  }

  span a {
    color: var(--banner-color);

    :hover {
      text-decoration: underline;
    }
  }
`
