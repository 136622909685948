import './App.css'
import React, { useState, useEffect } from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { Navbar, Footer } from './components'
import {
  MainPage,
  ContactUs,
  Goods,
  Policy,
  Cookies,
  Impressum,
  Gdpr,
  Error,
  Downloads,
} from './pages'
import ScrollToTop from './helper/ScrollToTop'
import smoothscroll from 'smoothscroll-polyfill'
import { GlobalContext } from './helper/GlobalContext'
import { CookieBanner } from '@keepist/react-gdpr-cookie-banner'

function App() {
  const [navClicked, setNavClicked] = useState(false)
  const [animationCanStart, setAnimationCanStart] = useState(true)

  const [scrollPosition, setScrollPosition] = useState(0)
  const handleScroll = () => {
    const position = window.pageYOffset
    setScrollPosition(position)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true })

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  useEffect(() => {
    if (scrollPosition === 0) {
      setTimeout(() => {
        setAnimationCanStart(true)
      }, 100)
    }
    //eslint-disable-next-line
  }, [scrollPosition, window.location.pathname])

  const clickValue = {
    navClicked,
    setNavClicked,
    animationCanStart,
    setAnimationCanStart,
  }

  smoothscroll.polyfill()

  return (
    <>
      <Router>
        <GlobalContext.Provider value={clickValue}>
          <Navbar />
          <ScrollToTop>
            <Routes>
              <Route path='/' element={<MainPage />} />
              <Route path='/contact' element={<ContactUs />} />
              <Route path='/impressum' element={<Impressum />} />
              <Route path='/documents' element={<Downloads />} />
              <Route path='/cookies' element={<Cookies />} />
              <Route path='/gdpr' element={<Gdpr />} />
              <Route path='/goods' element={<Goods />} />
              <Route path='/policy' element={<Policy />} />
              <Route path='*' element={<Error />} />
            </Routes>
          </ScrollToTop>
          <CookieBanner
            message='Ez a weboldal sütiket használ, amelyek segítik a weboldal működését.'
            showAcceptSelectionButton
            acceptAllButtonText='Összes elfogadása'
            acceptSelectionButtonText='Kijelöltek elfogadása'
            necessaryOptionText='Oldal működéséhez szükséges'
            preferencesOptionText='Preferenciák'
            statisticsOptionText='Statisztikák'
            marketingOptionText='Marketing'
            privacyPolicyLinkText='Süti tájékoztató'
            policyLink='/cookies'
          />
          <Footer />
        </GlobalContext.Provider>
      </Router>
    </>
  )
}

export default App
