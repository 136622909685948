import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Autoplay, Pagination } from 'swiper'
import { NavLink } from 'react-router-dom'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { Link } from 'react-scroll'

import bg from '../assets/images/polarbear.png'
import halfBox from '../assets/images/half-box.png'
import goods from '../assets/images/team.webp'
import cold from '../assets/images/cold.webp'
import packaging from '../assets/images/packaging.webp'
import img from '../assets/images/foam-brick.png'
import box from '../assets/images/box.png'
import foil from '../assets/images/foil2.png'
import test1 from '../assets/images/blue_ice_test.webp'
import test2 from '../assets/images/dry_ice_test.webp'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'

const MainPage = () => {
  const { t } = useTranslation()
  return (
    <HelmetProvider>
      <Helmet>
        <title>BE-PACK Home</title>
        <meta
          name='description'
          content='Cégünk fő profilja a hűtött áruk egyutas szállítási csomagolása.'
          data-react-helmet='true'
        />
      </Helmet>
      <Wrapper>
        <Swiper
          navigation={true}
          modules={[Navigation, Pagination, Autoplay]}
          pagination
          autoplay={{
            delay: 6000,
            disableOnInteraction: false,
          }}
          speed={1000}
          loop={true}
          className='mySwiper'>
          <SwiperSlide className='slide-block'>
            <div className='slide-bg'>
              <h1>{t('carousel-h1')}</h1>
              <h3>{t('carousel-1-h3')}</h3>
              <div className='btn-wrapper'>
                <a
                  className='hero-btn red'
                  href='#icebear'
                  offset={-150}
                  duration={100}>
                  {t('btn-more')}
                </a>
                <NavLink to='/contact' className='hero-btn blue'>
                  {t('contact')}
                </NavLink>
              </div>
            </div>
            <img loading='lazy' src={goods} alt='image1' />
          </SwiperSlide>
          <SwiperSlide className='slide-block'>
            <div className='slide-bg'>
              <h1>{t('carousel-h1')}</h1>
              <h3>{t('carousel-2-h3')}</h3>
              <div className='btn-wrapper'>
                <a
                  className='hero-btn red'
                  href='#icebear'
                  offset={-150}
                  duration={100}>
                  {t('btn-more')}
                </a>
                <NavLink to='/contact' className='hero-btn blue'>
                  {t('contact')}
                </NavLink>
              </div>
            </div>
            <img loading='lazy' src={cold} alt='image1' />
          </SwiperSlide>
          <SwiperSlide className='slide-block'>
            <div className='slide-bg'>
              <h1>{t('carousel-h1')}</h1>
              <h3>{t('carousel-3-h3')}</h3>
              <div className='btn-wrapper'>
                <a
                  className='hero-btn red'
                  href='#icebear'
                  offset={-150}
                  duration={100}>
                  {t('btn-more')}
                </a>
                <NavLink to='/contact' className='hero-btn blue'>
                  {t('contact')}
                </NavLink>
              </div>
            </div>
            <img loading='lazy' src={packaging} alt='image1' />
          </SwiperSlide>
        </Swiper>
        <div className='content-container' id='icebear'>
          <div className='intro'>
            <div className='intro-text'>
              <h2>{t('main-title')}</h2>
              <p>{t('main-desc')}</p>
              <div className='intro-btn-wrapper'>
                <NavLink to='/contact' className='btn red'>
                  {t('contact-1')}
                </NavLink>
              </div>
            </div>
            <div className='background first'>
              <img src={bg} alt='' />
            </div>
          </div>
          <div className='products-title'>
            <h2>{t('products')}:</h2>
          </div>
          <div className='products'>
            <div className='card'>
              <div className='card-header'>
                <h2>{t('products-box-title-1')}</h2>
              </div>
              <div className='card-body'>
                <img src={box} alt='foam brick' />
              </div>
            </div>
            <div className='card'>
              <div className='card-header'>
                <h2>{t('products-box-title-2')}</h2>
              </div>
              <div className='card-body'>
                <img src={img} alt='foam brick' />
              </div>
            </div>
            <div className='card'>
              <div className='card-header'>
                <h2>{t('products-box-title-3')}</h2>
              </div>
              <div className='card-body'>
                <img src={foil} alt='foam brick' />
              </div>
            </div>
            <div className='intro-btn-wrapper'>
              <NavLink to='/goods' className='btn product-btn red'>
                {t('btn-more')}
              </NavLink>
            </div>
          </div>
          <h2 className='divider-text'>{t('divider-text')}</h2>
          <div className='intro'>
            <div className='background second'>
              <img src={halfBox} alt='' />
            </div>
            <div className='intro-text'>
              <div className='list'>
                <p>{t('list-0')}</p>
                <p>{t('list-1')}</p>
                <p>{t('list-2')}</p>
                <p>{t('list-3')}</p>
                <p>{t('list-4')}</p>
                <p>{t('list-5')}</p>
              </div>
            </div>
          </div>
          <div className='products-title'>
            <h2>{t('testresults')}:</h2>
          </div>
          <div className='products'>
            <div className='test-card'>
              <div className='test-card-body'>
                <NavLink to='/documents'>
                  <img src={test1} alt='test-1' />
                </NavLink>
              </div>
            </div>
            <div className='test-card'>
              <div className='test-card-body'>
                <NavLink to='/documents'>
                  <img src={test2} alt='test-2' />
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </Wrapper>
    </HelmetProvider>
  )
}

const Wrapper = styled.main`
  padding-top: 130px;
  width: 100%;

  @media (max-width: 1024px) {
    padding-top: 80px;
  }

  .mySwiper {
    height: 60vh;
    margin-bottom: 5rem;
  }
  .swiper-slide {
    text-align: center;

    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }

  .slide-block {
    display: flex;
    flex-direction: column;
    position: relative;

    h1 {
      font-size: var(--main-title-font-size);
      color: var(--primary-bg);
      text-transform: uppercase;

      @media (max-width: 1024px) {
        font-size: calc(var(--main-title-font-size) - 0.4rem);
      }
    }

    h3 {
      font-size: calc(var(--title-font-size) + 0.8rem);
      color: var(--primary-bg);
      font-weight: 400;
      margin-bottom: 1rem;

      @media (max-width: 1024px) {
        font-size: calc(var(--title-font-size) - 0.9rem);
        font-weight: 500;
        text-align: center;
        margin-bottom: 0.5rem;
        margin-inline: 1rem;
      }
    }
  }

  .btn-wrapper {
    display: flex;
    width: 50%;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 1024px) {
      width: 90%;
    }
  }

  .red {
    background-color: var(--header-color);
  }
  .blue {
    background-color: var(--banner-color);
  }

  .hero-btn {
    display: inline-block;
    width: 40%;
    color: #fff;

    margin-top: 3rem;
    margin-inline: 1rem;
    padding: 1.2rem 0;
    border: 2px solid #fff;
    transition: all 0.3s ease-in-out;
    font-size: 1.2rem;
    font-weight: 600;
    text-transform: uppercase;

    :hover {
      cursor: pointer;
      background-color: transparent;
    }

    @media screen and (max-width: 1024px) {
      padding: 1rem 0;
      margin-top: 3rem;
      width: 60%;
    }
  }

  .slide-bg {
    width: 80%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and (max-width: 1024px) {
      width: 100%;
    }
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100vh;
    object-fit: cover;
    filter: brightness(0.5);

    @media (max-width: 1024px) {
      height: 60vh;
      object-fit: cover;
      filter: brightness(0.6);
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    color: white;

    @media (max-width: 1024px) {
      display: none;
    }
  }

  .swiper-pagination-bullet {
    background: var(--banner-color);
    opacity: 1;
    width: 3%;
    height: 4px;
    border-radius: 50px;

    @media (max-width: 1024px) {
      width: 8%;
      height: 3px;
    }
  }

  .swiper-pagination-bullet-active {
    background: var(--header-color);
  }

  .content-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 1280px;
    min-height: 100%;
    max-width: 90%;
    margin: auto;
    margin-bottom: 5rem;

    @media screen and (max-width: 1024px) {
      width: 100%;
      padding-top: 130px;
    }
    @media screen and (max-width: 480px) {
      width: 100%;
      padding-top: 0;
    }

    h2 {
      position: relative;
    }
  }

  .intro {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: auto;
    gap: 2rem;

    @media (max-width: 1024px) {
      flex-direction: column;
      height: 100%;
    }

    .intro-text {
      width: 50%;

      .list p {
        position: relative;
        display: flex;
        height: 120px;
        width: 100%;
        flex-direction: column;
        justify-content: center;
        padding-left: 38%;
        font-weight: 700;

        @media (max-width: 1024px) {
          width: 100%;
          padding: 0;
          height: 35px;
          padding-left: 1rem;
        }
      }

      .list p::before {
        position: absolute;
        content: '◉';
        bottom: 44px;
        left: 0;
        color: var(--banner-color);

        @media (max-width: 1024px) {
          position: absolute;
          content: '';
          top: 30%;
          left: 0px;
          width: 4px;
          height: 40%;
          background-color: var(--header-color);
        }
      }

      .list p::after {
        position: absolute;
        content: '';
        bottom: 56px;
        left: 5px;
        width: 35%;
        height: 4px;
        background-color: var(--banner-color);

        @media (max-width: 1024px) {
          display: none;
        }
      }
      @media (max-width: 1024px) {
        width: 100%;
        order: 2;
      }

      h2 {
        margin-bottom: 1rem;
        font-size: clamp(1.5rem, 0.94rem + 1.8667vw, 3.25rem);
        margin-left: 15px;

        ::before {
          position: absolute;
          content: '';
          top: 0;
          left: -15px;
          width: 5px;
          height: 100%;
          background-color: var(--banner-color);
        }
      }
    }

    .background {
      width: 50%;
      height: 100%;

      @media (max-width: 1024px) {
        width: 100%;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    .second img {
      width: 100%;
      height: 100%;
      object-fit: contain;

      @media (max-width: 1024px) {
        width: 100%;
        height: auto;
      }
    }

    .second,
    .first {
      position: relative;
    }

    .second::before {
      position: absolute;
      content: '';
      right: 10px;
      top: 0;
      width: 10px;
      height: 100%;
      background-color: var(--banner-color);

      @media (max-width: 1024px) {
        display: none;
      }
    }
    .second::after {
      position: absolute;
      content: '';
      right: 0;
      top: 0;
      width: 10px;
      height: 100%;
      background-color: var(--header-color);
      @media (max-width: 1024px) {
        display: none;
      }
    }
  }
  .products-title {
    margin-top: 5rem;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-start;

    @media (max-width: 1024px) {
      margin-top: 2.5rem;
    }

    h2 {
      margin-bottom: 1rem;
      font-size: clamp(1.5rem, 0.94rem + 1.8667vw, 3.25rem);
      margin-left: 15px;

      ::before {
        position: absolute;
        content: '';
        top: 0;
        left: -15px;
        width: 5px;
        height: 100%;
        background-color: var(--header-color);
      }
    }
  }

  .divider-text {
    background-color: var(--banner-color);
    margin-block: 5rem;
    padding: 1rem;
    text-align: center;
  }

  .products {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    flex: 1 1 auto;
    width: 100%;
    height: 100%;
    gap: 1rem;
    margin-top: 5rem;
    @media (max-width: 1024px) {
      margin-top: 1.5rem;
    }
    @media screen and (max-width: 768px) {
      flex-direction: column;
      gap: 3rem;
    }

    .test-card {
      display: flex;
      flex-direction: column;
      width: calc(50% - 1rem);
      min-height: calc(50% - 1rem);
      justify-content: center;
      text-align: center;
      align-items: center;

      @media screen and (max-width: 768px) {
        width: 100%;
        min-height: 550px;
      }

      .test-card-body {
        display: flex;
        height: 100%;
        width: 100%;

        img {
          width: 100%;
          height: auto;
          object-fit: contain;
        }
      }
    }

    .card {
      display: flex;
      flex-direction: column;
      width: calc(30% - 1rem);
      min-height: calc(30% - 1rem);
      justify-content: center;
      text-align: center;
      align-items: center;
      /* border: 1px solid var(--headline-text-color); */
      box-shadow: 5px 5px 8px rgba(0, 0, 0, 0.25);

      @media screen and (max-width: 768px) {
        width: 100%;
        height: 550px;
      }

      .card-header {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 25%;
        width: 100%;
        padding: 1rem;
        background-color: var(--banner-color);
        h2 {
          color: var(--primary-bg);
          font-size: clamp(1.125rem, 1.005rem + 0.4vw, 1.5rem);
        }
      }
      .card-body {
        display: flex;
        height: 75%;
        width: 100%;

        img {
          width: 100%;
          height: auto;
          vertical-align: middle;
          object-fit: cover;
        }
      }
    }
  }

  .intro-btn-wrapper {
    display: flex;
    width: 50%;
    align-items: center;
    justify-content: flex-start;

    @media screen and (max-width: 1024px) {
      width: 100%;
      justify-content: center;
    }

    .btn {
      display: inline-block;
      width: 100%;
      color: #fff;
      margin-top: 3rem;
      padding: 1.2rem 0;
      border: 2px solid #fff;
      transition: all 0.3s ease-in-out;
      font-size: 1.2rem;
      font-weight: 600;
      text-transform: uppercase;
      text-align: center;

      :hover {
        cursor: pointer;
        background-color: transparent;
        border: 2px solid #000;
        color: #000;
      }

      @media screen and (max-width: 1024px) {
        padding: 1rem 0;
        margin-top: 3rem;
        width: 60%;
      }
    }

    .product-btn {
      width: 50%;
    }
  }
`

export default MainPage
