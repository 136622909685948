import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import blob from '../assets/images/cool-background-white.svg'

const Cookies = () => {
  const { t } = useTranslation()
  return (
    <Wrapper>
      <div className='cookie-fo-tarolo'>
        <div>
          <h1>{t('cookie-h1')}</h1>
          <span>{t('main-cookie')}</span>
        </div>
        <div>
          <h2>{t('what-a-cookie-title')}</h2>
          <span>{t('what-a-cookie')}</span>
        </div>
        <div>
          <h2>{t('how-use-cookies-title')}</h2>
          <span>{t('how-use-cookies')}</span>
        </div>
        <div className='cookie-tipusok'>
          <h2>{t('what-type-cookie-title')}</h2>
          <span>{t('what-type-cookie')}</span>

          <h2>{t('cookie-types')}</h2>
          <div>
            <span className='cookie-tipus'>{t('cookie-type-1-title')} </span>
            <span>{t('cookie-type-1')}</span>
          </div>
          <div>
            <span className='cookie-tipus'>{t('cookie-type-3-title')} </span>
            <span>{t('cookie-type-3')}</span>
          </div>
          <div>
            <span className='cookie-tipus'>{t('cookie-type-4-title')} </span>
            <span>{t('cookie-type-4')}</span>
          </div>
          <div>
            <h2>{t('cookie-type-5-title')} </h2>
            <div>
              <span className='bold cookie-tipus'>{t('adwords')}</span>
              <span className='light'>
                <a
                  href='https://policies.google.com/privacy?hl=hu'
                  rel='noreferrer'
                  target='_blank'>
                  {t('service')}
                </a>
              </span>
            </div>
            <div>
              <span className='bold cookie-tipus'>{t('facebook')}</span>
              <span className='light'>
                <a
                  href='https://www.facebook.com/policies/cookies/'
                  rel='noreferrer'
                  target='_blank'>
                  {t('service')}
                </a>
              </span>
            </div>
          </div>
          <div>
            <h2>{t('switch-cookie')}</h2>
            <div>
              <span>{t('answer-sw-cookie')} </span>
            </div>
          </div>
          <div>
            <h2>{t('browsers-title')}</h2>
            <div>
              <span className='cookie-tipus'>
                <a
                  href='https://support.google.com/accounts/answer/61416?hl=hu'
                  rel='noreferrer'
                  target='_blank'>
                  Google Chrome
                </a>
              </span>
            </div>
            <div>
              <span className='cookie-tipus'>
                <a
                  href='https://support.mozilla.org/hu/kb/sutik-informacio-amelyet-weboldalak-tarolnak-szami'
                  rel='noreferrer'
                  target='_blank'>
                  Firefox
                </a>
              </span>
            </div>
            <div>
              <span className='cookie-tipus'>
                <a
                  href='https://support.microsoft.com/hu-hu/windows/a-microsoft-edge-a-böngészési-adatok-és-az-adatvédelem-bb8174ba-9d73-dcf2-9b4a-c582b4e640dd'
                  rel='noreferrer'
                  target='_blank'>
                  Microsoft Edge
                </a>
              </span>
            </div>
            <div>
              <span className='cookie-tipus'>
                <a
                  href='https://support.apple.com/hu-hu/guide/safari/sfri11471/mac'
                  rel='noreferrer'
                  target='_blank'>
                  Safari
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.main`
  width: 100%;
  background: url(${blob}) no-repeat top/cover fixed;
  .cookie-fo-tarolo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 200px;
    width: 1280px;
    height: 100%;
    max-width: 90%;
    margin: auto;
    padding-bottom: 5rem;

    @media screen and (max-width: 1024px) {
      width: 100%;
      padding-top: 30px;
    }
  }

  h1 {
    margin-bottom: 1rem;
  }

  h2 {
    position: relative;
    margin-top: 2rem;
    margin-bottom: 1rem;
    padding-left: 10px;
  }

  h2::before {
    position: absolute;
    left: 0;
    top: 0;
    content: '';
    width: 5px;
    height: 100%;
    background-color: var(--banner-color);
  }

  .cookie-tipusok span {
    padding-top: 1rem;
  }

  .cookie-tipus {
    position: relative;
    display: flex;
    max-width: 500px;
    font-weight: 700;
    padding-left: 20px;
  }

  .cookie-tipus::before {
    position: absolute;
    left: 0;
    top: 0;
    content: '◼︎';
    padding-top: 1rem;
    color: var(--header-color);
  }

  .cookie-tipus ~ span {
    display: block;
    padding-block: 0.5rem;
  }

  .bold {
    font-weight: 700;
  }

  span a {
    color: var(--banner-color);

    :hover {
      text-decoration: underline;
    }
  }
`

export default Cookies
