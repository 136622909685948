import React, { useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Helmet, HelmetProvider } from 'react-helmet-async'

import img from '../assets/images/foam-brick.png'
import box from '../assets/images/box.png'
import foil from '../assets/images/foil2.png'
import img1 from '../assets/images/img1.webp'
import img2 from '../assets/images/img2.webp'

const Goods = () => {
  const { t } = useTranslation()
  const [visibleText, setVisibleText] = useState(false)

  return (
    <HelmetProvider>
      <Helmet>
        <title>BE-PACK Products</title>
        <meta
          name='description'
          content='Our products are made of high quality materials. We offer a wide range of products, from foam bricks to foil.'
          data-react-helmet='true'
        />
      </Helmet>
      <Wrapper>
        <div className='background'>
          <h1>{t('goods')}</h1>
        </div>
        <div className='content-container'>
          <div
            className='card'
            onMouseEnter={() => setVisibleText('card1')}
            onMouseLeave={() => setVisibleText(false)}>
            <div className='card-header'>
              <h2>{t('products-box-title-1')}</h2>
            </div>
            <div className='card-body-img'>
              <img src={box} alt='foam brick' />
            </div>
            <div
              className={`card-body-text  card-1 ${
                visibleText === 'card1' ? 'visible-text' : ''
              }`}>
              <div>
                <p>{t('box-1-text-1')}</p>
                <ul>
                  <li>400x400x400mm {t('box')}</li>
                  <li>600x600x600mm {t('box')}</li>
                  <li>800x800x800mm {t('box')}</li>
                  <li>1200x800x700mm {t('box')}</li>
                </ul>
              </div>
              <div>
                <p>{t('box-1-text-2')}</p>
                <ul>
                  <li>{t('title-2-li-1')}</li>
                  <li>{t('title-2-li-2')}</li>
                </ul>
              </div>
              <div>
                <p>{t('box-1-text-3')}</p>
                <ul>
                  <li>{t('title-3-li-1')}</li>
                </ul>
              </div>
            </div>
          </div>
          <div
            className='card'
            onMouseEnter={() => setVisibleText('card2')}
            onMouseLeave={() => setVisibleText(false)}>
            <div className='card-header'>
              <h2>{t('products-box-title-2')}</h2>
            </div>
            <div className='card-body-img'>
              <img src={img} alt='foam brick' />
            </div>
            <div
              className={`card-body-text ${
                visibleText === 'card2' ? 'visible-text' : ''
              }`}>
              <p>{t('box-2-text-1')}</p>
              <p>{t('box-2-text-2')}</p> <p>{t('box-2-text-3')}</p>
            </div>
          </div>
          <div
            className='card'
            onMouseEnter={() => setVisibleText('card3')}
            onMouseLeave={() => setVisibleText(false)}>
            <div className='card-header'>
              <h2>{t('products-box-title-3')}</h2>
            </div>
            <div className='card-body-img'>
              <img src={foil} alt='foam brick' />
            </div>
            <div
              className={`card-body-text ${
                visibleText === 'card3' ? 'visible-text' : ''
              }`}>
              <p>{t('box-3-text-1')}</p>
              <p>{t('box-3-text-2')}</p>
            </div>
          </div>
        </div>
        <div className='content-container'>
          <div className='card-simple'>
            <img src={img1} alt='' />
          </div>
          <div className='card-simple'>
            <img src={img2} alt='' />
          </div>
        </div>
      </Wrapper>
    </HelmetProvider>
  )
}

const Wrapper = styled.main`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 130px;
  width: 1280px;
  height: 100%;
  max-width: 90%;
  margin: auto;
  margin-bottom: 5rem;

  @media screen and (max-width: 1024px) {
    width: 100%;
    padding-top: 80px;
  }

  .background {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 30vh;
    background: linear-gradient(to left, rgb(255, 0, 0), rgb(0, 167, 225));
    h1 {
      color: var(--primary-bg);
      text-shadow: 5px 5px 16px rgba(0, 0, 0, 0.55);
    }

    @media (max-width: 1024px) {
      height: 30vh;
    }
  }

  .content-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: stretch;
    flex: 1 1 auto;
    width: 100%;
    height: 100%;
    gap: 1rem;
    margin-top: 5rem;
    @media screen and (max-width: 768px) {
      flex-direction: column;
      gap: 3rem;
    }

    .card {
      position: relative;
      display: flex;
      flex-direction: column;
      width: calc(33.33% - 1rem);
      min-height: calc(33.33% - 1rem);
      justify-content: center;
      text-align: center;
      border: 1px solid #f5f5f5;
      box-shadow: 5px 5px 8px rgba(0, 0, 0, 0.25);
      cursor: pointer;

      @media screen and (max-width: 768px) {
        width: 100%;
        height: 550px;
      }

      .card-header {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 20%;
        background-color: var(--banner-color);
        h2 {
          color: var(--primary-bg);
          font-size: clamp(1.125rem, 1.005rem + 0.4vw, 1.5rem);
        }
      }
      .card-body {
        &-img {
          display: flex;
          height: 80%;
          img {
            width: 100%;
            height: 100%;
          }
        }
        &-text {
          display: flex;
          position: absolute;
          top: 20%;
          left: 0;
          width: 100%;
          height: 80%;
          flex-direction: column;
          align-items: center;
          justify-content: space-evenly;
          padding: 2rem;
          background-color: #fff6f2;
          font-weight: bold;
          z-index: -1;
          opacity: 0;
          transition: all 0.4s ease;

          li {
            list-style-type: none;
          }
        }
      }

      .card-1 p {
        text-decoration: underline;
      }
      .visible-text {
        display: flex;
        opacity: 1;
        z-index: 1;
      }
    }

    .card-simple {
      position: relative;
      display: flex;
      flex-direction: column;
      width: calc(50% - 1rem);
      min-height: calc(50% - 1rem);
      justify-content: center;
      text-align: center;
      box-shadow: 5px 5px 8px rgba(0, 0, 0, 0.25);

      @media screen and (max-width: 768px) {
        width: 100%;
        height: auto;
      }
    }
  }
`

export default Goods
