import { NavLink, Link } from 'react-router-dom'
import styled from 'styled-components'
import React, { useState, useContext } from 'react'
import { FaMapMarkerAlt } from 'react-icons/fa'
import { FaRegEnvelope } from 'react-icons/fa'
import { FaPhone } from 'react-icons/fa'
import logo from '../assets/logos/lpgo.svg'
import { GlobalContext } from '../helper/GlobalContext'
import { useTranslation } from 'react-i18next'

const Navbar = () => {
  const { t, i18n } = useTranslation()
  const changeLanguage = (language) => {
    i18n.changeLanguage(language)
  }
  const [clicked, setClicked] = useState(false)
  const { setNavClicked, setAnimationCanStart } = useContext(GlobalContext)

  function navClickHandle() {
    setAnimationCanStart(false)
    setNavClicked(true)
  }

  return (
    <Wrapper id='nav'>
      <div className='header-top'>
        <div className='header-top__left'>
          <span>
            <FaMapMarkerAlt />
            2660 Balassagyarmat, Rákóczi fejedelem út 5.
          </span>
          <a href='mailto:info@bepack.hu'>
            <span>
              <FaRegEnvelope />
              info@bepack.hu
            </span>
          </a>
          <a href='tel:+36309192950'>
            <span>
              <FaPhone />
              +36(30)919-2950
            </span>
          </a>
        </div>
      </div>
      <div className='desktop-menu-wrapper'>
        <NavLink to='/' aria-label='main page' role='button'>
          <span onClick={navClickHandle}>
            <img className='main-logo' src={logo} alt='logo' />
          </span>
        </NavLink>
        <div className='desktop-menu'>
          <NavLink
            to='/'
            activeclassname='active'
            aria-label='main page'
            role='button'>
            <span onClick={navClickHandle}>{t('mainpage')}</span>
          </NavLink>
          <NavLink to='/goods' activeclassname='active'>
            <span onClick={navClickHandle}>{t('goods')}</span>
          </NavLink>
          <NavLink to='/documents' activeclassname='active'>
            <span onClick={navClickHandle}>{t('downloads')}</span>
          </NavLink>
          <NavLink to='/contact' activeclassname='active'>
            <span onClick={navClickHandle}>{t('contact')}</span>
          </NavLink>
          <div className='language-wrapper'>
            {i18n.language === 'hu' ? (
              <button
                className='language-switcher'
                onClick={() => changeLanguage('en')}>
                EN
              </button>
            ) : (
              <button
                className='language-switcher'
                onClick={() => changeLanguage('hu')}>
                HU
              </button>
            )}
          </div>
        </div>
      </div>

      <div className='mobile-wrapper'>
        <NavLink to='/' aria-label='main page' role='button'>
          <span onClick={navClickHandle}>
            <img className='main-logo' src={logo} alt='' />
          </span>
        </NavLink>
        <div className='mobile-nav'>
          <div className='hamburger-ul' onClick={() => setClicked(!clicked)}>
            <div className={`hamburger-1 ${clicked ? 'close-1' : ''}`}></div>
            <div className={`hamburger-2 ${clicked ? 'close-2' : ''}`}></div>
            <div className={`hamburger-3 ${clicked ? 'close-3' : ''}`}></div>
          </div>
          <div className={`mobile-menu ${clicked ? 'active' : ''}`}>
            <div className='nav-items'>
              <Link to='/' activeclassname='m-active'>
                <span
                  className='mobile-menuitem'
                  onClick={() => setClicked(false)}>
                  {t('mainpage')}
                </span>
              </Link>
              <Link to='/goods' activeclassname='m-active'>
                <span
                  className='mobile-menuitem'
                  onClick={() => setClicked(false)}>
                  {t('goods')}
                </span>
              </Link>
              <Link to='/documents' activeclassname='m-active'>
                <span
                  className='mobile-menuitem'
                  onClick={() => setClicked(false)}>
                  {t('downloads')}
                </span>
              </Link>
              <Link to='/contact' activeclassname='m-active'>
                <span
                  className='mobile-menuitem'
                  onClick={() => setClicked(false)}>
                  {t('contact')}
                </span>
              </Link>
              <hr />
              <div className='language-wrapper'>
                {i18n.language === 'hu' ? (
                  <button
                    className='language-switcher'
                    onClick={() => {
                      changeLanguage('en')
                      setClicked(false)
                    }}>
                    EN
                  </button>
                ) : (
                  <button
                    className='language-switcher'
                    onClick={() => {
                      changeLanguage('hu')
                      setClicked(false)
                    }}>
                    HU
                  </button>
                )}
              </div>
            </div>

            <ul className='header-bottom'>
              <li>
                <a href='tel:+36309192950'>
                  <FaPhone />
                  +36(30)919-2950
                </a>
              </li>
              <li>
                <a href='mailto:info@bepack.hu'>
                  <FaRegEnvelope />
                  info@bepack.hu
                </a>
              </li>
              <li>
                <FaMapMarkerAlt />
                2660 Balassagyarmat, Rákóczi fejedelem út 5.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.nav`
  position: fixed;
  top: 50px;
  left: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  .header-top {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: fixed;
    inset: 0 0 0 0;
    width: 100%;
    height: 50px;
    background-color: var(--header-color);
    font-size: 1rem;
    font-family: var(--title-font-style);

    .header-top__left,
    .header-top__right {
      display: flex;
      flex-direction: row;
      gap: 2.5rem;
    }
    span {
      display: flex;
      flex-direction: row;
      align-items: center;
      list-style: none;
      gap: 0.5rem;
      color: var(--primary-bg);
    }

    img {
      padding-right: 0.5rem;
    }
    span {
      font-weight: 700;
    }
  }

  .main-logo {
    height: 70px;
  }

  .desktop-menu-wrapper {
    display: flex;
    width: 1280px;
    max-width: 90%;
    margin-top: 50px;
    height: 80px;
    flex-direction: row;
    list-style: none;
    align-items: center;
    justify-content: space-between;
    font-size: var(--paragraph-font-size);
    gap: 2rem;
    color: var(--text-color);
    background-color: var(--primary-bg);
    font-family: var(--title-font-style);
    font-weight: 700;

    @media (max-width: 1024px) {
      display: none;
    }
  }

  .desktop-menu {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 1.2rem;
    font-size: var(--paragraph-font-size);

    span {
      position: relative;
      cursor: pointer;
      text-decoration: none;
      list-style: none;
      position: relative;
      transition: all 0.3s ease;

      :hover {
        color: var(--header-color);
      }
    }

    span::after {
      content: '';
      transition: all 0.3s ease;
    }

    .active {
      position: relative;
      transition: all 0.3s ease-in-out;
      color: var(--header-color);
    }

    .active::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 3px;
      background-color: var(--banner-color);
    }

    a {
      display: flex;
      justify-content: center;
      color: var(--text-color);
    }

    span:hover::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 3px;
      background-color: var(--banner-color);
    }
  }

  .mobile-wrapper {
    display: none;
  }

  @media (max-width: 1024px) {
    .mobile-wrapper {
      display: flex;
      width: 100%;
      height: 80px;
      align-items: center;
      justify-content: space-between;
      padding-inline: 10%;
      background: var(--primary-bg);
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    }

    .header-top {
      display: none;
    }

    .hamburger-ul {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: space-evenly;
      width: 22px;
      height: 25px;
      cursor: pointer;
    }

    .hamburger-1,
    .hamburger-2,
    .hamburger-3 {
      width: 20px;
      height: 3px;
      background-color: var(--text-color);
      transition: all 0.3s ease-in-out;
    }

    .close-1 {
      transform: rotate(45deg) translateY(6px);
    }
    .close-3 {
      transform: rotate(-45deg) translateY(-6px);
    }
    .close-2 {
      display: none;
    }

    .mobile-menu {
      position: absolute;
      top: 80px;
      right: 0;
      display: flex;
      flex-direction: column;
      background-color: var(--text-color);
      width: 100%;
      height: calc(100vh - 80px);
      align-items: center;
      justify-content: space-evenly;
      transform: translateX(100%);
      transition: all 0.3s ease-in-out;
      padding: 2rem 10%;
      list-style: none;

      button.language-switcher {
        margin-bottom: 1rem;
      }

      .nav-items {
        display: flex;
        flex-direction: column;
        height: 50vh;
        align-items: center;
        justify-content: space-between;
      }
    }

    hr {
      margin: 10% 5%;
      border-radius: 0;
      border: none;
      height: 2px;
      width: 90vw;
      background-color: var(--header-color);
    }

    .header-bottom {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 15%;
      background-color: var(--text-color);

      li {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        list-style: none;
        margin-block-end: 0.5rem;
        color: var(--primary-bg);
        padding-block: 0.3rem;

        a {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          color: var(--primary-bg);
        }
      }

      li p {
        color: var(--primary-bg);
      }

      img {
        padding-right: 0.5rem;
      }
      span {
        font-weight: 700;
      }
    }

    .active {
      transform: translateX(0);
    }

    .mobile-menuitem {
      font-weight: 700;
      position: relative;
      width: 100%;
      transition: all 0.3s ease-in-out;
      text-decoration: none;
      font-size: 1.5rem;
      color: var(--primary-bg);
    }
  }
  button.language-switcher {
    border: none;
    font-size: var(--paragraph-font-size);
    background-color: var(--primary-bg);
    padding: 5px 15px;
    border-radius: 25px;
    transition: all 0.3s ease;
    font-family: var(--title-font-style);

    :hover {
      cursor: pointer;
      color: var(--banner-color);
    }
    :active {
      transform: rotate(360deg);
    }

    @media screen and (max-width: 1024px) {
      padding: 15px;
      border-radius: 50%;
    }
  }
`

export default Navbar
