import React from 'react'
import styled from 'styled-components'
import logo from '../assets/logos/lpgo.svg'
// import { FaFacebook } from 'react-icons/fa'
// import { FaInstagram } from 'react-icons/fa'
// import { FaTwitter } from 'react-icons/fa'

import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const Footer = () => {
  const { t } = useTranslation()
  return (
    <Wrapper>
      <div className='content-container'>
        <div className='block block-1'>
          <NavLink to='/' aria-label='main page'>
            <img src={logo} alt='BePack kft. logo' />
          </NavLink>
        </div>
        <div className='block block-2'>
          <ul>
            <li>
              <NavLink to='/'>{t('mainpage')}</NavLink>
            </li>
            <li>
              <NavLink to='/goods'>{t('goods')}</NavLink>
            </li>
            <li>
              <NavLink to='/documents'>{t('downloads')}</NavLink>
            </li>
            <li>
              <NavLink to='/contact'>{t('contact')}</NavLink>
            </li>
          </ul>
        </div>
        <div className='block block-3'>
          <ul>
            <li>
              <NavLink to='/impressum'>{t('impressum-title')}</NavLink>
            </li>

            <li>
              <NavLink to='/cookies'>{t('cookies')}</NavLink>
            </li>
            <li>
              <NavLink to='/gdpr'>{t('gdpr')}</NavLink>
            </li>
            <li>
              <NavLink to='/policy'>{t('policy')}</NavLink>
            </li>
          </ul>
        </div>
      </div>
      {/* <div className='block-4'>
        <div className='social-block'>
          <a
            href='https://www.facebook.com/klimamindenkinek.klimamindenkinek.1'
            target='_blank'
            rel='noreferrer'
            className='icon'>
            {<FaFacebook />}
          </a>
          <a href='#' className='icon'>
            <FaInstagram />
          </a>
          <a href='#' target='_blank' rel='noreferrer' className='icon'>
            <FaTwitter />
          </a>
        </div>
      </div> */}
      <p className='copy'>Copyright &copy;2022 All rights reserved.</p>
    </Wrapper>
  )
}

const Wrapper = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-block: 4rem;
  color: var(--primary-bg);
  background: var(--text-color);
  @media screen and (max-width: 1024px) {
    min-height: calc(100vh - 100px);
    padding-inline: 5%;
  }

  .content-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: auto;
    width: 1280px;
    max-width: 90%;
    height: 50%;
    gap: 10%;

    @media screen and (max-width: 1024px) {
      flex-direction: column;
      align-items: center;
      text-align: center;
      padding-top: 1rem;
      gap: 5%;
      height: 100%;
    }
  }

  p {
    color: var(--primary-bg);
    font-size: 16px;
  }

  .block {
    display: flex;
    flex-direction: column;
    font-size: 16px;

    @media screen and (max-width: 1024px) {
      margin-block: 0.5rem;
    }
  }

  .block-1 {
    width: 40%;

    @media screen and (max-width: 1024px) {
      width: 100%;
    }
  }

  .block-2,
  .block-3 {
    display: flex;
    flex-direction: column;

    ul li {
      list-style-type: none;

      a {
        color: var(--primary-bg);
        transition: all 0.2s ease;
        padding-bottom: 0.2rem;

        :hover {
          text-decoration: underline;
          color: var(--header-color);
        }
      }
    }
    li {
      padding-bottom: 0.5rem;
    }
  }

  .block-4 {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: center;
  }

  .social-block {
    display: flex;
    width: 50%;
    height: 100%;
    flex-direction: row;
    padding-top: 1rem;
    align-items: center;
    justify-content: center;
    gap: 2.5rem;
    margin-block: 2rem;

    a {
      font-size: 1.5rem;
      color: white;
      transition: all 0.2s ease;
      height: 100%;
      display: flex;

      :hover {
        transform: scale(1.2);
        color: var(--header-color);
      }
    }

    @media screen and (max-width: 1024px) {
      width: 50%;
      margin: 1rem auto;

      .block-2 {
        margin-block: 2rem;
      }

      a {
        font-size: 3rem;
      }
    }
  }

  .copy {
    margin-top: 1rem;
  }
`

export default Footer
