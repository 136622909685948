import i18n from 'i18next'
import HttpApi from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

i18n
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: ['hu', 'en'],
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
    debug: false,
    detection: {
      order: ['localStorage', 'cookie', 'path', 'htmlTag'],
      cache: ['localStorage', 'cookie'],
    },
    backend: {
      loadPath: '/locales/{{lng}}/translation.json',
    },
    react: { useSuspense: false },
  })

export default i18n
