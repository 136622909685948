import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import blob from '../assets/images/cool-background-white.svg'
import iso from '../assets/images/iso9001.webp'

const Policy = () => {
  const { t } = useTranslation()
  return (
    <Wrapper>
      <div className='content-container'>
        <div className='title'>
          <h1>{t('policy')}</h1>
        </div>
        <div className='content'>
          <p>{t('policy-text-1')}</p>
          <h2>{t('policy-basic')}</h2>
          <p>{t('policy-basic-text-1')}</p>
          <p>{t('policy-basic-text-2')}</p>
          <p>{t('policy-basic-text-3')}</p>
          <p>{t('policy-basic-text-4')}</p>
          <p>{t('policy-basic-text-5')}</p>
        </div>
        <div className='iso-img'>
          <img loading='lazy' src={iso} alt='iso9001 cert' />
          <h3>{t('iso-reg')} ICH-180603</h3>
        </div>
      </div>
    </Wrapper>
  )
}

export default Policy

const Wrapper = styled.main`
  width: 100%;
  background: url(${blob}) no-repeat top/cover fixed;
  .content-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-top: 200px;
    width: 1280px;
    height: 100%;
    max-width: 90%;
    margin: auto;
    padding-bottom: 5rem;

    @media screen and (max-width: 1024px) {
      width: 100%;
      padding-top: 30px;
    }

    .title {
      margin-bottom: 2rem;
    }

    p {
      margin-block: 1rem;
    }

    p:last-child {
      font-weight: 700;
    }

    .iso-img {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      margin-top: 3rem;

      h3 {
        margin-top: 1rem;
      }
    }
  }
`
