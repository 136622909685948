import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import blob from '../assets/images/cool-background-blue.svg'

const Impressum = () => {
  const { t } = useTranslation()

  return (
    <Wrapper>
      <div className='content-wrapper'>
        <div className='main-title'>
          <h1>{t('impressum-title')}</h1>
        </div>
        <div className='hosting'>
          <h2>{t('i-hosting')}</h2>
          <h4>xxxxxxxx</h4>
          <picture>
            {/* <img className='logo' src={netlifyLogo} alt='Netlify logo' /> */}
          </picture>
          <div>
            <h3>{t('headquarters')}</h3>
            <p>
              Lorem ipsum dolor sit, amet consectetur adipisicing elit.
              Consequatur, repudiandae.
            </p>
            <h3>{t('e-address')}</h3>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Deserunt,
              explicabo?
            </p>
            <h3>Email:</h3>
            <p>lorem.@lorem.com</p>
          </div>
        </div>

        <div className='owner'>
          <h2>{t('operator')}</h2>
          <h4>BE Pack Korlátolt Felelősségű Társaság</h4>
          <picture>{/* <img src={logo} alt='Netlify logo' /> */}</picture>
          <div>
            <h3>{t('address')}</h3>
            <p>2660 Balassagyarmat, Rákóczi fejedelem út 5. 2. em. 1.</p>
            <h3>{t('reg-authority')}</h3>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
            <h3>{t('taxNumber')}</h3>
            <p>25038789-2-12</p>
            <p>HU25038789</p>
            <h3>{t('companyNumber')}</h3>
            <p>12-09-009016</p>
            <h3>{t('phone')}</h3>
            <p>+36(30)919-2950</p>
            <h3>{t('mail')}</h3>
            <p>info@bepack.hu</p>
            <h3>{t('kamara')}</h3>
            <p>Nógrád Megyei Kereskedelmi és Iparkamara</p>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.main`
  width: 100%;
  background: url(${blob}) no-repeat top/cover fixed;

  .content-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-top: 200px;
    width: 1280px;
    height: 100%;
    max-width: 90%;
    margin: auto;
    padding-bottom: 5rem;

    @media screen and (max-width: 1024px) {
      width: 100%;
      padding-top: 30px;
    }
  }
  .main-title {
    margin-bottom: 2rem;

    @media (max-width: 1024px) {
      text-align: center;
    }
  }

  h1 {
    display: inline-block;
    font-size: var(--main-title-font-size);
    position: relative;
  }

  h1::after {
    position: absolute;
    left: 0;
    bottom: 0;
    content: '';
    width: 100%;
    height: 5px;
    background-color: var(--text-color);
  }

  .hosting h2,
  .owner h2 {
    margin: 1rem 0;
    position: relative;
    padding-left: 10px;
    font-size: var(--title-font-size);
  }

  .owner h2 {
    margin-top: 2rem;
  }

  .hosting h4,
  .owner h4 {
    margin-block: 2rem;
    font-size: calc(var(--subtitle-font-size) + 0.2rem);
    font-weight: 400;

    @media (max-width: 1024px) {
      text-align: center;
    }
  }

  h2::before {
    position: absolute;
    left: 0;
    top: 0;
    content: '';
    width: 5px;
    height: 100%;
    background-color: var(--banner-color);
  }

  h3 {
    margin: 1rem 0 0.3rem;
    position: relative;
    padding-left: 20px;
    font-size: var(--subtitle-font-size);
  }

  h3::before {
    position: absolute;
    left: 0;
    bottom: -3px;
    content: '◼︎';
    color: var(--header-color);
  }
`

export default Impressum
