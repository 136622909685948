import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Helmet, HelmetProvider } from 'react-helmet-async'

import img from '../assets/images/foam-brick.png'
import box from '../assets/images/box.jpeg'
import test1 from '../assets/images/blue_ice_test.webp'
import test2 from '../assets/images/dry_ice_test.webp'

import pdf1 from '../assets/downloads/spec.pdf'
import pdf2 from '../assets/downloads/BE Pack Flexible Insulation Foil.pdf'
import pdf3 from '../assets/downloads/BE Pack insulation foil presentation.pdf'
import pdf4 from '../assets/downloads/blue.pdf'
import pdf5 from '../assets/downloads/dry.pdf'

const Downloads = () => {
  const { t } = useTranslation()
  return (
    <HelmetProvider>
      <Helmet>
        <title>BE-PACK Documents</title>
        <meta
          name='description'
          content='Download our documents and test results. Our products are made of high quality materials. We offer a wide range of products, from foam bricks to foil.'
          data-react-helmet='true'
        />
      </Helmet>
      <Wrapper>
        <div className='background'>
          <h1>{t('downloads')}</h1>
        </div>
        <div className='content-container'>
          <div className='card'>
            <div className='card-header'>
              <h2>{t('brick')}</h2>
            </div>
            <div className='card-body'>
              <img src={img} alt='foam brick' />
            </div>
            <div className='card-footer'>
              <a className='btn' href={pdf1} rel='noreferrer' target='_blank'>
                {t('download')}
              </a>
            </div>
          </div>
          <div className='card'>
            <div className='card-header'>
              <h2>{t('foil-hu')}</h2>
            </div>
            <div className='card-body'>
              <img src={box} alt='foam brick' />
            </div>
            <div className='card-footer'>
              <a className='btn' href={pdf2} rel='noreferrer' target='_blank'>
                {t('download')}
              </a>
            </div>
          </div>
          <div className='card'>
            <div className='card-header'>
              <h2>{t('foil-en')}</h2>
            </div>
            <div className='card-body'>
              <img src={box} alt='foam brick' />
            </div>
            <div className='card-footer'>
              <a className='btn' href={pdf3} rel='noreferrer' target='_blank'>
                {t('download')}
              </a>
            </div>
          </div>
          <div className='card'>
            <div className='card-header'>
              <h2>{t('blue')}</h2>
            </div>
            <div className='card-body'>
              <img src={test1} alt='test result pdf img' />
            </div>
            <div className='card-footer'>
              <a className='btn' href={pdf4} rel='noreferrer' target='_blank'>
                {t('download')}
              </a>
            </div>
          </div>
          <div className='card'>
            <div className='card-header'>
              <h2>{t('dry')}</h2>
            </div>
            <div className='card-body'>
              <img src={test2} alt='test result pdf img' />
            </div>
            <div className='card-footer'>
              <a className='btn' href={pdf5} rel='noreferrer' target='_blank'>
                {t('download')}
              </a>
            </div>
          </div>
        </div>
      </Wrapper>
    </HelmetProvider>
  )
}

export default Downloads

const Wrapper = styled.main`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 130px;
  width: 1280px;
  height: 100%;
  max-width: 90%;
  margin: auto;
  margin-bottom: 5rem;

  @media screen and (max-width: 1024px) {
    width: 100%;
    padding-top: 80px;
  }

  .background {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 30vh;
    background: linear-gradient(to left, rgb(255, 0, 0), rgb(0, 167, 225));
    h1 {
      color: var(--primary-bg);
      text-shadow: 5px 5px 16px rgba(0, 0, 0, 0.55);
      text-align: center;
    }

    @media (max-width: 1024px) {
      height: 30vh;
    }
  }
  .content-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: stretch;
    flex: 1 1 auto;
    width: 100%;
    height: 100%;
    gap: 1rem;
    margin-top: 5rem;
    @media screen and (max-width: 768px) {
      flex-direction: column;
      gap: 3rem;
    }

    .card {
      display: flex;
      flex-direction: column;
      width: calc(33.33% - 1rem);
      min-height: calc(33.33% - 1rem);
      justify-content: center;
      text-align: center;
      margin-top: 2rem;
      /* border: 1px solid var(--headline-text-color); */
      box-shadow: 5px 5px 8px rgba(0, 0, 0, 0.25);
      @media screen and (max-width: 768px) {
        width: 100%;
        height: 550px;
      }

      .card-header {
        display: flex;
        align-items: center;
        justify-content: center;
        max-height: 100px;
        height: 100px;
        padding: 1rem;
        background-color: var(--banner-color);
        h2 {
          color: var(--primary-bg);
          font-size: clamp(1.125rem, 1.005rem + 0.4vw, 1.5rem);
        }
      }
      .card-body {
        display: flex;
        height: calc(100% - 200px);

        img {
          width: 100%;
          height: auto;
          object-fit: cover;
          background-position: top;
        }
      }
      .card-footer {
        display: flex;
        align-items: center;
        justify-content: center;
        max-height: 100px;
        height: 100px;
        padding: 1rem;
        background-color: var(--primary-bg);
      }
    }
  }

  .btn {
    display: flex;
    text-align: center;
    justify-content: center;
    color: var(--headline-text-color);
    background: var(--primary-bg);
    padding: 1rem 2rem;
    border: 2px solid var(--headline-text-color);
    transition: all 0.3s ease-in-out;
    font-size: 1.2rem;
    font-weight: 700;
    text-transform: uppercase;

    :hover {
      cursor: pointer;
      background-color: var(--headline-text-color);
      color: var(--primary-bg);
    }

    @media screen and (max-width: 1024px) {
      padding: 0.5rem 1rem;
    }
  }
`
