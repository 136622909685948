import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Helmet, HelmetProvider } from 'react-helmet-async'

import { FaMapMarkerAlt } from 'react-icons/fa'
import { FaRegEnvelope } from 'react-icons/fa'
import { FaPhone } from 'react-icons/fa'

import bear from '../assets/images/polarbear.png'

const ContactUs = () => {
  const { t } = useTranslation()
  return (
    <HelmetProvider>
      <Helmet>
        <title>BE-PACK Contact</title>
        <meta
          name='description'
          content='Contact us for more information. We are located in Balassagyarmat, Rákóczi fejedelem út 5.'
          data-react-helmet='true'
        />
      </Helmet>
      <Wrapper>
        <div className='background'>
          <h1>{t('contact')}</h1>
        </div>
        <div className='content-wrapper'>
          <div className='contact-data'>
            <div className='data-bg'>
              <img src={bear} alt='' />
            </div>
            <div className='text-wrapper'>
              <div className='text-top'>
                <div className='left'>
                  <FaPhone size={'2rem'} color={'#00a7e1'} />
                  <h2>{t('phone')}</h2>
                  <a href='tel: +36309192950'>+36(30)919-2950</a>
                </div>
                <div className='right'>
                  <FaRegEnvelope size={'2rem'} color={'#00a7e1'} />
                  <h2>{t('mail')}</h2>
                  <a href='mailto: info@bepack.hu'>info@bepack.hu</a>
                </div>
              </div>
              <div className='text-bottom'>
                <FaMapMarkerAlt size={'2rem'} color={'#00a7e1'} />
                <h2>{t('headquarters')}</h2>
                <p>2660 Balassagyarmat, Rákóczi fejedelem út 5.</p>
              </div>
            </div>
          </div>
          <div className='map'>
            <iframe
              loading='lazy'
              title='bepack'
              scrolling='no'
              marginHeight='0'
              marginWidth='0'
              src='https://maps.google.com/maps?q=Balassagyarmat+R%C3%A1k%C3%B3czi+fejedelem+%C3%BAt+5.&amp;ie=UTF8&amp;output=embed'
              width='100%'
              height='100%'
              frameBorder='0'></iframe>
          </div>
        </div>
      </Wrapper>
    </HelmetProvider>
  )
}

const Wrapper = styled.main`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 130px;
  width: 1280px;
  height: 100%;
  max-width: 90%;
  margin: auto;
  margin-bottom: 5rem;

  @media screen and (max-width: 1024px) {
    width: 100%;
    padding-top: 80px;
  }

  .background {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 30vh;
    background: linear-gradient(to left, rgb(255, 0, 0), rgb(0, 167, 225));
    h1 {
      color: var(--primary-bg);
      text-shadow: 5px 5px 16px rgba(0, 0, 0, 0.55);
    }

    @media (max-width: 1024px) {
      height: 30vh;
    }
  }

  .content-wrapper {
    margin-top: 5rem;
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 50vh;
    gap: 2rem;

    @media screen and (max-width: 1024px) {
      flex-direction: column;
      min-height: 100vh;
    }

    .map {
      width: 50%;
      height: 50vh;

      @media screen and (max-width: 1024px) {
        width: 100%;
        height: 100%;
        margin: 0;
      }
    }

    .contact-data {
      position: relative;
      width: 50%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      @media screen and (max-width: 1024px) {
        width: 100%;
      }

      .data-bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        filter: opacity(0.2);

        img {
          width: 100%;
          height: 100%;
        }
      }

      .text-wrapper {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        h2 {
          text-align: center;
          font-size: var(--title-font-size);
        }

        a,
        p {
          text-decoration: none;
          color: var(--text-color);
          font-size: var(--title-font-size);
          text-align: center;

          @media screen and (max-width: 1024px) {
            font-size: calc(var(--subtitle-font-size) + 0.2rem);
          }
        }

        .text-top {
          display: flex;
          width: 100%;
          height: 50%;
          flex-direction: row;
          align-items: center;
          justify-content: center;

          @media screen and (max-width: 1024px) {
            flex-direction: column;
            height: 100%;
          }

          .left,
          .right {
            width: 50%;
            display: flex;
            flex-direction: column;
            align-items: center;

            @media screen and (max-width: 1024px) {
              height: 100%;
            }
          }
        }
        .text-bottom {
          display: flex;
          flex-direction: column;
          width: 100%;
          height: 50%;
          align-items: center;
          justify-content: center;

          h2 {
            width: 100%;
          }
        }
      }
    }
  }
`

export default ContactUs
