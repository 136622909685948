import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import polarBear from '../assets/images/polarbear.png'

const Error = () => {
  const { t } = useTranslation()
  return (
    <Wrapper>
      <div className='content-wrapper'>
        <div className='text-content'>
          <h1 className='main-text'>{t('404-msg')}</h1>
          <Link to='/' className='btn'>
            {t('404-btn')}
          </Link>
        </div>
        <div className='bg'></div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.main`
  position: fixed;
  position: relative;
  top: 0;
  left: 0;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: var(--primary-bg);

  @media screen and (max-width: 1024px) {
    width: 100%;
    padding-top: 30px;
  }

  .content-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    @media screen and (max-width: 1024px) {
      flex-direction: column;
    }

    .text-content,
    .bg {
      width: 50%;
      height: 100%;

      @media screen and (max-width: 1024px) {
        width: 100%;
      }
    }

    .text-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
    }

    .bg {
      background: url(${polarBear}) no-repeat center/contain;
    }
  }

  .btn {
    margin-top: 3rem;
    display: inline-block;
    color: var(--headline-text-color);
    background: transparent;
    padding: 1rem 2rem;
    border: 2px solid var(--headline-text-color);
    transition: all 0.3s ease-in-out;
    font-size: 1.2rem;
    font-weight: 700;
    text-transform: uppercase;

    :hover {
      cursor: pointer;
      background-color: var(--headline-text-color);
      color: var(--primary-bg);
    }

    @media screen and (max-width: 1024px) {
      padding: 0.5rem 1rem;
      margin-top: 3rem;
    }
  }
`

export default Error
